
import React from 'react'


const ExperimentStatus = ({ status }: any) => {
    switch (status) {
        case "ACTIVE":
            return <span className="badge bg-success-subtle  text-success text-uppercase">Activo</span>;
        case "DRAFT":
            return <span className="badge bg-primary-subtle  text-info text-uppercase">Borrador</span>;
        case "CANCELLED":
            return <span className="badge bg-danger-subtle  text-danger text-uppercase">Cancelado</span>;
        case "INACTIVE":
            return <span className="badge bg-primary-subtle  text-info text-uppercase">Inactivo</span>;
        case "COMPLETED":
            return <span className="badge bg-success-subtle text-success  text-uppercase">Completada</span>;
    }
}


export default ExperimentStatus 