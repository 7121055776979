import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Input, InputGroup } from "reactstrap"
import { selectTemplate } from "selectors"
import TemplatePreviewModal from "./TemplatePreviewModal"


const TemplateDropdown = ({
    value,
    onBlur,
    onChange,
    invalid,
    name,
    selectLabel,
    showItem,
    className,
    required,
    showPreviewButton,
}: any) => {
    const { templateItems } = useSelector(selectTemplate)
    const [show, setShow] = useState(false)
    const [templateId, setTemplateId] = useState(null as (null | string))

    const toggle = () => {
        setShow(!show)
    }
    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setTemplateId(value)
        onChange(e)
    }

    return <React.Fragment>
        <InputGroup>
            <Input
                type="select"
                required={required}
                name={name}
                className={className}
                value={value}
                onBlur={onBlur}
                onChange={_onChange}
                invalid={invalid}
            >
                <option value="">{selectLabel}</option>
                {(templateItems || [])
                    .filter((each: any) => !showItem || showItem(each))
                    .map((each: any) => <option
                        value={each.id}
                        disabled={each.status !== 'APPROVED'}
                        key={each.id}>
                        {each.name}
                    </option>)}
            </Input>
        </InputGroup>

    </React.Fragment>
}

export default TemplateDropdown