const CampaignConditionConfig: any = {
    ALL_TIME: {
        ORDER: [
            {
                name: 'change_type',
                field: 'dropdown',
                values: ['SHIPPING_STATUS'],
            },
            {
                name: 'shipping_status',
                field: 'dropdown',
                showIf: (values: any) => values['change_type'] == 'SHIPPING_STATUS',
                values: ['ALL', 'PREPARING', 'SENT', 'FAILED', 'DELIVERED', 'READY_FOR_PICKUP'],
            },
            {
                name: 'when',
                field: 'dropdown',
                values: ['immediatly', 'hours', 'days', 'weeks', 'months', 'years'],
            },
            {
                name: 'hours',
                field: 'time',
                showIf: (values: any) => values['when'] == 'hours',
            },
            {
                name: 'days',
                field: 'number',
                showIf: (values: any) => values['when'] == 'days',
            },
            {
                name: 'weeks',
                field: 'number',
                showIf: (values: any) => values['when'] == 'weeks',
            },
            {
                name: 'months',
                field: 'number',
                showIf: (values: any) => values['when'] == 'months',
            },
            {
                name: 'years',
                field: 'number',
                showIf: (values: any) => values['when'] == 'years',
            },
        ],
        USER: [
            {
                name: 'action',
                field: 'dropdown',
                values: ['IF_PURCHASE', 'IF_ABANDONED', 'CUSTOMERS_ON_CREATION'],
            },
            {
                name: 'criteria',
                field: 'dropdown',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE',
                values: ['PAYMENT_METHOD', 'ANY_PRODUCT', 'PRODUCT', 'SPENT', 'CATEGORY'],
            },
            {
                name: 'payment_method',
                field: 'payment_method',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE' && values['criteria'] == 'PAYMENT_METHOD',
            },
            {
                name: 'skus',
                field: 'tags',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE' && values['criteria'] == 'PRODUCT',
            },
            {
                name: 'product_categories',
                field: 'product_categories',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE' && values['criteria'] == 'CATEGORY',
            },
            {
                name: 'spent',
                field: 'dropdown',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE' && values['criteria'] === 'SPENT',
                values: ['AT_LEAST', 'LESS_OR_EQUAL'],
            },
            {
                name: 'spent_amount',
                field: 'number',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE' && values['criteria'] === 'SPENT',
            },
            {
                name: 'frequency',
                field: 'dropdown',
                showIf: (values: any) => values['action'] === 'IF_PURCHASE' && ['PRODUCT', 'ANY_PRODUCT'].includes(values['criteria']),
                values: ['EACH_TIME', 'LAST_TIME'],
            },
            {
                name: 'when',
                field: 'dropdown',
                values: ['immediatly', 'hours', 'days', 'weeks', 'months', 'years'],
            },
            {
                name: 'hours',
                field: 'time',
                showIf: (values: any) => values['when'] == 'hours',
            },
            {
                name: 'days',
                field: 'number',
                showIf: (values: any) => values['when'] == 'days',
            },
            {
                name: 'weeks',
                field: 'number',
                showIf: (values: any) => values['when'] == 'weeks',
            },
            {
                name: 'months',
                field: 'number',
                showIf: (values: any) => values['when'] == 'months',
            },
            {
                name: 'years',
                field: 'number',
                showIf: (values: any) => values['when'] == 'years',
            },
        ],
    },
    ONE_TIME: {
        FOLLOW_UP: [
            {
                name: 'mode',
                field: 'dropdown',
                values: ['before_24_hours', 'normal_sent']
            },
            {
                name: 'wait_time',
                field: 'time',
            },
        ],
        SEGMENT: [
            {
                name: 'user',
                field: 'dropdown',
                values: ['IF_PURCHASE', 'ENGAGED_CUSTOMERS']
            },
            {
                name: 'criteria',
                field: 'dropdown',
                showIf: (values: any) => values['user'] == 'IF_PURCHASE',
                values: ['PAYMENT_METHOD', 'ANY_PRODUCT', 'PRODUCT', 'SPENT', 'CATEGORY'],
            },
            {
                name: 'product_categories',
                field: 'product_categories',
                showIf: (values: any) => values['criteria'] === 'CATEGORY',
            },
            {
                name: 'spent_type',
                field: 'dropdown',
                showIf: (values: any) => values['criteria'] == 'SPENT',
                values: ['AVG', 'TOTAL', 'more_than'],
            },
            {
                name: 'amount',
                field: 'number',
                showIf: (values: any) => values['criteria'].includes('SPENT', 'AVG'),
            },
            {
                name: 'payment_method',
                field: 'payment_method',
                showIf: (values: any) => values['criteria'] == 'PAYMENT_METHOD',
            },
            {
                name: 'skus',
                field: 'tags',
                showIf: (values: any) => values['criteria'] == 'PRODUCT',
            },
            {
                name: 'frequency',
                field: 'dropdown',
                showIf: (values: any) => ['ANY_PRODUCT', 'CATEGORY'].indexOf(values['criteria']) !== -1,
                values: ['at_least', 'at_maximum', 'more_than', 'less_than', 'exactly', 'last_time'],
            },
            {
                name: 'quantity_time',
                showIf: (values: any) => ['ANY_PRODUCT', 'CATEGORY'].indexOf(values['criteria']) !== -1 && values['frequency'] !== 'last_time',
                field: 'number',
            },
            {
                name: 'interval',
                field: 'dropdown',
                showIf: (values: any) => (
                    (['at_least', 'at_maximum', 'more_than', 'less_than', 'exactly', 'last_time'].indexOf(values['frequency']) !== -1 && values['criteria'] === 'ANY_PRODUCT')
                    || (['PAYMENT_METHOD', 'SPENT', 'CATEGORY'].indexOf(values['criteria']) !== -1 || (values['criteria'] == 'SPENT' && values['spent_type'] == 'TOTAL'))
                ),
                values: ['before', 'after', 'between', 'exactly_day'],
            },
            {
                name: 'interval',
                field: 'dropdown',
                showIf: (values: any) => (
                    values['criteria'] === 'PRODUCT'
                ),
                values: ['history', 'before', 'after', 'between', 'exactly_day', 'last_time_before', 'last_time_after', 'last_time_between'],
            },
            {
                name: 'interval',
                field: 'dropdown',
                showIf: (values: any) => (
                    values['user'] === 'ENGAGED_CUSTOMERS'
                ),
                values: ['before', 'after', 'between'],
            },
            {
                name: 'date_a',
                field: 'date',
                showIf: (values: any) => (((['before', 'after', 'between', 'exactly_day', 'last_time_before', 'last_time_after', 'last_time_between'].indexOf(values['interval']) !== -1 && ['at_least', 'at_maximum', 'more_than', 'less_than', 'exactly', 'last_time'].indexOf(values['frequency']) !== -1) || (['SPENT'].indexOf(values['criteria']) !== -1)) || ((['before', 'after', 'between', 'exactly_day', 'last_time_before', 'last_time_after', 'last_time_between'].indexOf(values['interval']) !== -1) && (['PRODUCT'].indexOf(values['criteria']) !== -1)) || ((['before', 'after', 'between'].includes(values['interval']) && ['ENGAGED_CUSTOMERS'].indexOf(values['user']) !== -1))),
            },
            {
                name: 'date_b',
                field: 'date',
                showIf: ((values: any) => (['between', 'last_time_between'].indexOf(values['interval']) !== -1 && ['at_least', 'at_maximum', 'more_than', 'less_than', 'exactly', 'last_time'].indexOf(values['frequency']) !== -1) || (['between', 'last_time_between'].indexOf(values['interval']) !== -1 && ['SPENT'].indexOf(values['criteria']) !== -1) || ((['between', 'last_time_between'].indexOf(values['interval']) !== -1) && (['PRODUCT'].indexOf(values['criteria']) !== -1)) || ((['between'].includes(values['interval']) && ['ENGAGED_CUSTOMERS'].indexOf(values['user']) !== -1))),
            },
            {
                name: 'include_created',
                field: 'checkbox',
                defaultValue: true,
                showIf: (values: any) => values['user'] === 'ENGAGED_CUSTOMERS',
            },
            {
                name: 'include_updated',
                field: 'checkbox',
                defaultValue: true,
                showIf: (values: any) => values['user'] === 'ENGAGED_CUSTOMERS',
            },
            {
                name: 'include_buyers',
                field: 'checkbox',
                defaultValue: true,
                showIf: (values: any) => values['user'] === 'ENGAGED_CUSTOMERS',
            },
            {
                name: 'include_abandoned',
                field: 'checkbox',
                defaultValue: true,
                showIf: (values: any) => values['user'] === 'ENGAGED_CUSTOMERS',
            },
            {
                name: 'exclude_active_subscribers',
                field: 'checkbox',
                showIf: (values: any) => values['user'] === 'ENGAGED_CUSTOMERS',
            },
            {
                name: 'exclude_recent_buyers_days',
                field: 'number',
                showIf: (values: any) => values['user'] === 'ENGAGED_CUSTOMERS',
            }

        ],
    },
}

export default CampaignConditionConfig
