import CampaignAbandonedMetrics from "Components/Campaigns/CampaignAbandonedMetrics";
import CampaignConvertionMetrics from "Components/Campaigns/CampaignConvertionMetrics";
import CampaignFilter from "Components/Campaigns/CampaignFilter";
import CampaignGroupConvertionPie from "Components/Campaigns/CampaignGroupConvertionPie";
import CampaignGroupReportLoader from "Components/Campaigns/CampaignGroupReportLoader";
import CampaignTransactionalMetrics from "Components/Campaigns/CampaignTransactionalMetrics";
import StoreMetrics from "Components/Campaigns/StoreMetrics";
import VariantReport from "Components/Campaigns/VariantReport";
import useCurrentStore from "Components/Hooks/CurrentStore";
import { Permission, hasPermissions } from "common/security";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { selectCampaigns, selectOrder, selectProfile } from "selectors";
import { getReport, getStoreReport } from "slices/campaign/thunk";
import { getOrderList } from "slices/order/thunk";
import { OrderStatus } from "types/Dashboard";
import { useQueryParams, JsonParam } from "use-query-params";

const CampaignDashboard = () => {
  useCurrentStore()
  const dispatch: any = useDispatch();

  const { campaignReport, status } = useSelector(selectCampaigns)
  const profile = useSelector(selectProfile)

  const { orderItems } = useSelector(selectOrder)
  const [currency, setCurrency] = useState<string>('USD')
  const [params, setParams] = useQueryParams<any>({
    campaign: null,
    fromDate: null,
    toDate: null,
    group: null,
  })

  document.title = "Dashboard | Burbuxa";

  const canSeeOrders = hasPermissions([Permission.STORE_ORDERS], profile)

  useEffect(() => {
    if (profile.currentStoreId && canSeeOrders) {

      dispatch(
        getOrderList({
          storeId: profile.currentStoreId,
          params: {
            status: OrderStatus.CONVERTED
          }
        })
      )
    }
  }, [profile.currentStoreId])

  useEffect(() => {
    if (profile.currentStoreId && hasPermissions([Permission.METRICS], profile)) {
      if (!params.campaign && !params.group) {
        dispatch(
          getStoreReport({
            storeId: profile.currentStoreId,
            from_date: params.fromDate,
            to_date: params.toDate,
          })
        )
      } else if (params.campaign) {
        dispatch(
          getReport({
            storeId: profile.currentStoreId,
            campaignId: params.campaign,
            from_date: params.fromDate,
            to_date: params.toDate,
          })
        )
      } else if (params.group) {
        dispatch(
          getReport({
            storeId: profile.currentStoreId,
            groupId: params.group,
            from_date: params.fromDate,
            to_date: params.toDate,
          })
        )
      }
    }
  }, [profile.currentStoreId, params.campaign, params.fromDate, params.toDate, params.group])

  if (!hasPermissions([Permission.METRICS], profile)) {
    return <></>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <CampaignFilter
                  values={params}
                  onChange={(values: any) => {
                    if (values.currency) {
                      setCurrency(values.currency)
                    } else {
                      setParams({
                        ...params,
                        ...values
                      })
                    }
                  }
                  }
                />
              </div>
            </Col>
          </Row>
          {params.group && status == 'loading' &&
            <CampaignGroupReportLoader />
          }
          <Row>
            {campaignReport?.distribution && <Col xxl={12} className="text-center">
              <CampaignGroupConvertionPie distribution={campaignReport.distribution} />
            </Col>}
            <Col xxl={12}>
              {(!params.campaign && !params.group) && <StoreMetrics currency={currency} report={campaignReport} orderItems={orderItems} />}
              {params.campaign && campaignReport && campaignReport.campaign && <>
                {campaignReport.variants && campaignReport.variants.length > 0 &&
                  <VariantReport currency={currency} variants={campaignReport.variants} />
                }
                {(campaignReport.campaign.campaign_type == 'CONVERSION' && campaignReport.campaign.rule == 'IF_ABANDONED') &&
                  <CampaignAbandonedMetrics currency={currency} report={campaignReport} />}
                {(campaignReport.campaign.campaign_type == 'CONVERSION' && campaignReport.campaign.rule != 'IF_ABANDONED') &&
                  <CampaignConvertionMetrics currency={currency} report={campaignReport} />}
                {campaignReport.campaign.campaign_type == 'TRANSACTIONAL' && <CampaignTransactionalMetrics currency={currency} report={campaignReport} />}
              </>}

              {params.group && campaignReport && campaignReport.groupType == 'ABANDONED' &&
                <CampaignAbandonedMetrics currency={currency} report={campaignReport} />}
              {params.group && campaignReport && campaignReport.groupType == 'CONVERSION' &&
                <CampaignConvertionMetrics currency={currency} report={campaignReport} />}
              {params.group && campaignReport && campaignReport.groupType == 'TRANSACTIONAL' &&
                <CampaignTransactionalMetrics currency={currency} report={campaignReport} />}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignDashboard;
