import { Link } from "react-router-dom"
import CampaignStatus from "./CampaignStatus"
import Select from 'react-select';
import CampaignActionDropdown from "./CampaignActionDropdown"
import { format } from "date-fns"
import ExperimentStatus from "./ExperimentStatus"
import { Permission, hasPermissions } from "common/security";
import { useSelector } from "react-redux";
import { selectGroup, selectProfile } from "selectors";
import { useDispatch } from "react-redux";
import { updateCampaign } from "slices/campaign/thunk";
import { useCallback } from "react";


const groupStyles = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        borderWidth: state.isFocused ? '1px' : '0',
        borderColor: state.isFocused ? 'grey' : 'red',
        fontSize: '12px',
    }),
    menu: (baseStyles: any, state: any) => ({
        ...baseStyles,
        borderWidth: state.isFocused ? '1px' : '0',
        borderColor: state.isFocused ? 'grey' : 'red',
        fontSize: '12px',
        lineHeight: '1em',
    }),
}

const CampaignTable = ({ items, loading }: any) => {
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const groups = useSelector(selectGroup)

    const onChangeGroup = useCallback((campaignId: string, group: string) => {
        dispatch(
            updateCampaign({
                storeId: profile.currentStoreId,
                campaignId: campaignId,
                payload: {
                    campaign_group_id: group
                }
            })
        )
    }, [profile.currentStoreId])

    return <div className="table-responsive">
        <table className="table align-middle position-relative table-nowrap">
            <thead className="table-active">
                <tr>
                    <th scope="col" className="text-center">Nombre</th>
                    <th scope="col" className="text-center">Estado</th>
                    {hasPermissions([Permission.CAMPAIGN_EDIT], profile) && <th scope="col" className="text-center">Grupo</th>}
                    <th scope="col" className="text-center">Experimento</th>
                    <th scope="col" className="text-center">Última edición</th>
                    <th scope="col" className="text-center">Última ejecución</th>
                    <th scope="col" className="text-center">Acciones</th>
                </tr>
            </thead>

            <tbody id="campaign-list">
                {(loading || items == null) && Array(5).fill(0).map((key, index) => <tr key={index}>
                    {Array(5).fill(0).map((_, sIndex) => <td key={sIndex}>
                        <p className="card-text placeholder-glow">
                            <span className="placeholder col-12"></span>
                        </p>
                    </td>)}
                </tr>)}
                {(items || []).map((item: any, key: any) => (<tr key={key}>
                    <td>
                        <div className="text-center text-primary">
                            {item.name}
                        </div>
                    </td>
                    <td className="text-center">
                        <CampaignStatus status={item.status} />
                    </td>
                    {hasPermissions([Permission.CAMPAIGN_EDIT], profile) && <td className="">
                        <Select
                            placeholder='Seleccionar grupo'
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            menuPlacement="auto"
                            styles={groupStyles}
                            options={[
                                { value: null, label: 'Sin grupo' },
                                ...(groups.groupItems || []).map((each: any) => ({
                                    value: each.id,
                                    label: each.name,
                                }))]
                            }
                            value={
                                item.campaign_group_id ?
                                    [groups.groupItems
                                        .map((each: any) => ({ value: each.id, label: each.name }))
                                        .find((each: any) => each.value == item.campaign_group_id)]
                                    : null}
                            onChange={(value: any) => {
                                onChangeGroup(item.id, value.value)
                            }}
                        />
                    </td>}
                    <td className="text-center">
                        {item.experiment ? <ExperimentStatus status={item.experiment.status} /> : <span>-</span>}
                    </td>
                    <td className="text-center">
                        <span className="text-dark">{item.updated_at ? format(new Date(item.updated_at), 'dd/MM/yyyy') : item.created_at ? format(new Date(item.created_at), 'dd/MM/yyyy') : '-'}</span>
                    </td>
                    <td className="text-center">
                        <span className="text-dark">{item.last_eval_date ? format(new Date(item.last_eval_date), 'dd/MM/yyyy HH:mm') : '-'}</span>
                    </td>
                    <td className="text-center">
                        <CampaignActionDropdown campaign={item} />
                    </td>
                </tr>))}
            </tbody>
        </table>
    </div >
}

export default CampaignTable
