
export const OrderStatusMessage: { [key: string]: string } = {
    CONVERTED: 'Convertida',
    CONFIRMED: 'Confirmada',
    CREATED: 'Creada',
    ABANDONED: 'Abandonada',
    PAYMENT_PENDING: 'Pendiente de pago',
}

export enum OrderStatus {
    CONVERTED = "CONVERTED",
    CONFIRMED = "CONFIRMED",
    CREATED = "CREATED",
    ABANDONED = "ABANDONED",
    PAYMENT_PENDING = "PAYMENT_PENDING",
}

export interface Paginated<T> {
    data: T[]
    total: number
    skip?: number
    limit?: number
}

export interface IMember {
    id: string
    email: string
    name?: string
    role: 'admin' | 'member'
}

export interface IBusiness {
    id: string
    name: string
    email: string
    description?: string
    country: string
    verified: boolean
    phone?: string
    url?: string
    logo?: string
}

export interface buttonsMessagesTypeCTA {
    type?: string
    text?: string
    target?: string
}

export interface IMessage {
    id: string
    templateId?: string
    language: string
    type: 'CTA' | 'TXT' | 'MDA'
    name: string
    text: string
    buttonType?: string
    buttonText?: string
    target?: string
    status: 'APPROVED' | 'PENDING' | 'REJECTED'
    createdAt: string
    updatedAt: string
    storeId?: string
    media?: any
    media_type?: any
    buttons?: [buttonsMessagesTypeCTA]
}


export type CampaignType =
    | 'TRANSACTIONAL'
    | 'CONVERSION'


export interface ICampaign {
    id: string
    name: string
    message_template_id: string
    follow_up_campaign_id: string
    campaign_group_id: string
    campaign_type: CampaignType
    messageTemplateId: string | undefined
    ruleTemplateId: string
    status: string
    rule: string
    method: 'RULE' | 'IMPORT' | 'ALL' | 'FOLLOW_UP'
    rule_params?: any
    waitingTime: number
    notConvertedTemplateId: string
    notConvertedWaitingTime: number
    active?: boolean
    notConvertedActive: boolean
    created_at: string
    updated_at: string
    paid_at?: string
    last_eval_date: string
    evaluation_type: string
    updatedAt: string
    storeId?: string
    start_date?: string
    end_date?: string
    type?: string
    coupon?: string
    range_type?: string
    upload?: any
    attribution_time?: string
    experiment?: any
}

export interface getApi {
    data: { items: [object]; total: number; skip: number; limit: number }
}


export interface CampaignBasicType {
    id: number
    name: string
    campaign_type: CampaignType
    rule: string
}

export interface IReport {
    campaignId?: string
    timestamp?: number
    // data
    campaign: CampaignBasicType
    group: any
    groupType: any
    roi: number
    abandonedCartVolume: number
    abandonedCartCount: number
    totalRecoveredCarts: number
    totalMessagesSent: number
    totalRecoveredVolume: number
    totalConversionRate: number
    totalCost: number
    totalClientsReached: number
    totalInvestment: number
    totalOrders: number
    totalInvestmentStore: number
    totalRecoveredVolumeStore: number
}

export interface Distribution {
    campaign_id: string
    campaign_name: string
    amount: number
}

export interface CampaignDistribution {
    distribution: Distribution[]
    total_usd: number
}

export interface INotification {
    id: string
    type: 'success' | 'error' | 'info'
    message: string
    callToActionUrl?: string
    seen: boolean
}

export interface IBilling {
    activeProfiles: number
    totalProfiles: number
    messagesSent: number
    totalMessages: number
    emailSent: number
    totalEmails: number
    fixedPrice: number
    variableCost: number
    totalAmount: number
    resetDate: string
    currentPlan: PlanNames
    updatedAt: string
}

export interface IBillingHistory {
    subscription_cost: number
    from_date: string
    to_date: string
    credits_consumed: number
}

export type PlanNames = 'free' | 'basic' | 'premium'

export interface IPricePlan {
    plan: PlanNames
    price: number
    description: string
    profiles: number
    emails: number
    analytics: 'daily' | 'weekly' | 'monthly'
    support: boolean
    sms: number
}

export interface Store {
    uid: string
    name: string
    support_phone: string
    country: string
    country_id: number
    email: string
    description: string
    url: string
    website: string
    logo: any
    platform_type: 'SHOPIFY' | 'WOOCOMMERCE' | 'MAGENTO' | 'VTEX'
}

export interface IInstallation {
    redirect_to: string
}

export interface IBodyTable {
    id: string
    lastMessage: string
    creationDate: string
    timeDifference: string
    discountCode: {
        name: string
        icon: 'info'
        description: string | JSX.Element
    }
    status: {
        name: string
        icon: 'status'
        status: 'check' | 'warning' | 'cancel'
    }
}

export interface ITypeStatus {
    check: JSX.Element
    warning: JSX.Element
    cancel: JSX.Element
}


export const TicketStatusColumns = ['NEW', 'IN_PROGRESS', 'WAITING_ON_CUSTOMER', 'STAND_BY', 'RESOLVED_BY_ASSISTANT', 'RESOLVED', 'CLOSED', 'ARCHIVED'] as const

export interface TicketContext {
    id: string
    message: string
    created_at: string
}

export interface ConversationContact {
    id: string
    phone: string
    name: string
    created_at: string
}

export interface BasicUser {
    uid: string
    first_name: string
    last_name: string
}

export interface Ticket {
    id: string
    created_at: string
    text: string
    description: string
    status: typeof TicketStatusColumns[number]
    conversation_contact: ConversationContact
    context: TicketContext[]
    assigned_user?: BasicUser | null
}

export const TicketStatusName: { [key in typeof TicketStatusColumns[number]]: string } = {
    NEW: 'Nuevo',
    IN_PROGRESS: 'En progreso',
    WAITING_ON_CUSTOMER: 'En espera del cliente',
    STAND_BY: 'Pausado',
    RESOLVED: 'Resuelto',
    CLOSED: 'Cerrado',
    ARCHIVED: 'Archivado',
    RESOLVED_BY_ASSISTANT: 'Solucionado por Burbujita',
}

export interface GenericContact {
    id: string
    first_name: string
    last_name?: string
    phone: string
    shipping_phone?: string
    billing_phone?: string
    created_at: string
    type?: 'CU' | 'IN'
}

export const TicketStatusColor: { [key in typeof TicketStatusColumns[number]]: string } = {
    NEW: 'warning',
    IN_PROGRESS: 'info',
    WAITING_ON_CUSTOMER: 'secondary',
    STAND_BY: 'danger',
    RESOLVED: 'success',
    RESOLVED_BY_ASSISTANT: 'success',
    CLOSED: 'dark',
    ARCHIVED: 'dark',
}

export interface Alert {
    id: number
    created_at: string
    type: string
    severity: 'INFO' | 'ERROR' | 'SUCCESS'
    message: string
}