import { formatNumber, formatSecondsToTime } from "helpers/format";
import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { ExperimentOn, ExperimentOnTitle } from "./constants";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectProfile } from "selectors";



const VariantReport = ({ variants, currency }: any) => {
    const { currentStoreCurrency } = useSelector(selectProfile)

    const transformedData = Object.values(variants).map((exp: any) => {
        let revenue = currency == 'USD' ? exp.total_attributed_revenue_usd_cents : exp.total_attributed_revenue_local_cents
        let spend = currency == 'USD' ? exp.total_spend_total_usd_cents : exp.total_spend_total_local_cents

        return {
            variant: exp.variant_name,
            total_attributed_revenue: revenue / 100,
            conversionCount: exp.total_attributed_orders_count,
            clientsReached: exp.total_unique_clients_reached_count,
            conversionRate: (exp.total_attributed_orders_count / exp.total_unique_clients_reached_count) * 100,
            avgConversionTime: exp.total_avg_conversion_time_seconds / exp.total_attributed_orders_count,
            readRate: (exp.total_messages_read_count / exp.total_messages_delivered_count) * 100,
            roas: revenue / spend,
            cpa: spend / exp.total_attributed_orders_count / 100,
            variant_name: exp.variant_name,
            variant_experiment_on: exp.variant_experiment_on,
            variant_message_template: exp.variant_message_template,
            variant_scheduled_at: exp.variant_scheduled_at,
            variant_rule_params: exp.variant_rule_params,
        }
    });

    const toParameter = (variant: any) => {
        if (variant.variant_experiment_on == ExperimentOn.SCHEDULED_AT) {
            return format(new Date(variant.variant_scheduled_at.replace('Z', '')), 'dd/MM/yyyy HH:mm')
        } else if (variant.variant_experiment_on == ExperimentOn.TEMPLATE) {
            return variant.variant_message_template
        } else if (variant.variant_experiment_on == ExperimentOn.TIME) {
            return Object.values(variant.variant_rule_params)[0]
        } else {
            return '-'
        }
    }

    return (
        <Row>
            <Col md={12}>
                <Card className="card-animate">
                    <CardBody>
                        <p className="text-uppercase fw-medium text-muted text-truncate">Variantes de experimento A / B</p>
                        <div className="table-responsive">
                            <Table bordered striped className="table-nowrap">
                                <thead className="table-active">
                                    <tr className="bg-gray-200">
                                        <th className="border p-2">Variante</th>
                                        <th className="border p-2">Tipo</th>
                                        <th className="border p-2">Parámetro</th>
                                        <th className="border p-2">Clientes</th>
                                        <th className="border p-2">Cant. conversiones</th>
                                        <th className="border p-2">Tasa de Conversión (%)</th>
                                        <th className="border p-2">Tasa de Apertura (%)</th>
                                        <th className="border p-2">Tiempo conversión</th>
                                        <th className="border p-2">Facturación</th>
                                        <th className="border p-2">ROAS</th>
                                        <th className="border p-2">CPA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transformedData.map((data, index) => (
                                        <tr key={index} className="text-center bg-white hover:bg-gray-100">
                                            <td className="border p-2 fw-bold">{data.variant}</td>
                                            <td className="border p-2">{ExperimentOnTitle[data.variant_experiment_on as keyof typeof ExperimentOnTitle]}</td>
                                            <td className="border p-2">{toParameter(data)}</td>
                                            <td className="border p-2">{formatNumber(data.clientsReached)}</td>
                                            <td className="border p-2">{formatNumber(data.conversionCount)}</td>
                                            <td className="border p-2">{formatNumber(data.conversionRate)}</td>
                                            <td className="border p-2">{formatNumber(data.readRate)}</td>
                                            <td className="border p-2">{formatSecondsToTime(data.avgConversionTime)}</td>
                                            <td className="border p-2">{currency === 'USD' ? 'USD' : currentStoreCurrency} {formatNumber(data.total_attributed_revenue)}</td>
                                            <td className="border p-2">{formatNumber(data.roas)}</td>
                                            <td className="border p-2">{currency === 'USD' ? 'USD' : currentStoreCurrency} {formatNumber(data.cpa)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col md={12}>
                <Card className="card-animate">
                    <CardBody>
                        <p className="text-uppercase fw-medium text-muted text-truncate">Tasa de conversión por variante</p>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={transformedData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="variant" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="conversionRate" fill="#8884d8" name="Tasa de Conversión (%)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    );
};

export default VariantReport;
