
export const CONTROL_GROUP = 'CONTROL';

export enum ExperimentOn {
    SCHEDULED_AT = "SCHEDULED_AT",
    TEMPLATE = "TEMPLATE",
    TIME = "TIME"
}


export const ExperimentOnTitle: { [key in ExperimentOn]: string } = {
    [ExperimentOn.SCHEDULED_AT]: 'Programación de envio',
    [ExperimentOn.TEMPLATE]: 'Plantilla a enviar',
    [ExperimentOn.TIME]: 'Tiempo',
}

export const ExperimentOnLabel: { [key in ExperimentOn]: string } = {
    [ExperimentOn.SCHEDULED_AT]: 'Programar envio para el',
    [ExperimentOn.TEMPLATE]: 'Plantilla a enviar',
    [ExperimentOn.TIME]: 'Tiempo',
}

export const ExperimentOnByCampaignType = {
    'ONE_TIME': [ExperimentOn.TEMPLATE, ExperimentOn.SCHEDULED_AT],
    'ALL_TIME': [ExperimentOn.TEMPLATE, ExperimentOn.TIME],
}