import TemplateDropdown from "Components/Templates/TemplateDropdown"
import { FieldArray, FormikProvider, FormikProps, FormikErrors } from "formik"
import { useEffect, useState, useMemo, useCallback, useRef } from "react"
import { Alert, Button, Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Input, Label, Row, UncontrolledTooltip, Spinner } from "reactstrap"
import { CONTROL_GROUP, ExperimentOn, ExperimentOnLabel, ExperimentOnTitle } from "./constants"
import TimeInput from "./TimeInput"
import { useSelector } from "react-redux"
import { selectProfile } from "selectors"
import { useDispatch } from "react-redux"
import { getTemplates } from "slices/templates/thunk"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es"
import ExperimentStatus from "./ExperimentStatus"
import moment from "moment"

interface Variant {
    variant_name: string;
    isControlGroup?: boolean;
    message_template_id?: string;
    scheduled_at?: string;
    time?: string;
    distribution_share?: number;
    status?: string;
}

interface Experiment {
    enabled_experiment: boolean;
    experiment_on: ExperimentOn;
    name: string;
    description: string;
    variants: Variant[];
    status?: string;
}

interface Campaign {
    experiment?: Experiment;
}

interface FormValues {
    experiment: Experiment;
    [key: string]: any;
}

interface ExperimentFormProps {
    campaign?: Campaign;
    formik: FormikProps<FormValues>;
}

const MIN_VARIANTS = 2;
const MAX_VARIANTS = 52;
const MAX_VARIANT_NAME_LENGTH = 50;
const MIN_NAME_LENGTH = 3;
const MIN_DESCRIPTION_LENGTH = 10;
const VARIANT_NAME_REGEX = /^[A-Za-z0-9\s-]+$/;
const MIN_DISTRIBUTION = 0.01; // 1%

// Add minimum scheduling window (1 hour from now)
const getMinScheduledDate = () => {
    const date = new Date();
    date.setHours(date.getHours() + 1);
    return date.toISOString().slice(0, 16);
};

// Add maximum scheduling window (1 year from now)
const getMaxScheduledDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date.toISOString().slice(0, 16);
};

const isVariantError = (error: unknown): error is FormikErrors<Variant> => {
    return typeof error === 'object' && error !== null && !('length' in error);
};

const ExperimentForm = ({ campaign, formik }: ExperimentFormProps) => {
    const { currentStoreId } = useSelector(selectProfile)
    const dispatch: any = useDispatch()
    const dateRef = useRef<any>(null)
    const [isAutoDistributing, setIsAutoDistributing] = useState(false);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [templateError, setTemplateError] = useState<string>('');
    const [lastRejectedName, setLastRejectedName] = useState<string>('');

    useEffect(() => {
        if (currentStoreId) {
            setIsLoadingTemplates(true);
            setTemplateError('');
            const promise = dispatch(
                getTemplates({
                    storeId: currentStoreId,
                    all: true,
                })
            );

            promise
                .catch((error: Error) => {
                    setTemplateError('Error al cargar las plantillas: ' + error.message);
                })
                .finally(() => setIsLoadingTemplates(false));

            return () => {
                promise.abort?.();
            };
        }
    }, [currentStoreId]);

    useEffect(() => {
        if (!formik.values.experiment.experiment_on) return;

        if (formik.values.experiment.variants?.length > 0) {
            const updatedVariants = formik.values.experiment.variants.map(variant => {
                if (!variant.isControlGroup) {
                    return {
                        ...variant,
                        message_template_id: undefined,
                        scheduled_at: undefined,
                        time: undefined,
                    };
                }
                return variant;
            });
            formik.setFieldValue("experiment.variants", updatedVariants);
        }
    }, [formik.values.experiment.experiment_on]);

    const redistributeShares = useCallback((variants: Variant[]) => {
        const count = variants.length;
        if (count === 0) return variants;

        const equalShare = 1 / count;
        return variants.map(variant => ({
            ...variant,
            distribution_share: equalShare
        }));
    }, []);

    const addControlGroup = () => {
        const variants = formik.values.experiment.variants || [];
        const updatedVariants = [
            {
                variant_name: CONTROL_GROUP,
                isControlGroup: true,
                distribution_share: variants.length > 0 ? 1 / (variants.length + 1) : 0.5,
            },
            ...variants
        ];
        setIsAutoDistributing(true);
        formik.setFieldValue("experiment.variants", redistributeShares(updatedVariants));
    };

    const addVariant = () => {
        const variants = formik.values.experiment.variants || [];
        const existingNames = new Set(variants.map(v => v.variant_name));

        // Find next available letter
        let nextLetter = '';
        for (let i = 0; i < 26; i++) {
            const letter = String.fromCharCode(65 + i);
            if (!existingNames.has(letter)) {
                nextLetter = letter;
                break;
            }
        }

        if (!nextLetter) {
            // If all letters are taken, append numbers
            for (let i = 1; ; i++) {
                const name = `A${i}`;
                if (!existingNames.has(name)) {
                    nextLetter = name;
                    break;
                }
            }
        }

        const updatedVariants = [
            ...(variants.filter(v => v.isControlGroup)),
            ...variants.filter(v => !v.isControlGroup),
            {
                variant_name: nextLetter,
                distribution_share: variants.length > 0 ? 1 / (variants.length + 1) : 1,
            }
        ];
        setIsAutoDistributing(true);
        formik.setFieldValue("experiment.variants", redistributeShares(updatedVariants));
    };

    const handleRemoveVariant = (remove: (index: number) => void, index: number) => {
        const variants = [...formik.values.experiment.variants];
        variants.splice(index, 1);
        remove(index);

        setIsAutoDistributing(true);
        setTimeout(() => {
            formik.setFieldValue("experiment.variants", redistributeShares(variants));
        }, 0);
    };

    const handleDistributionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setIsAutoDistributing(false);
        const inputValue = e.target.value;

        // Allow empty input for better UX
        if (inputValue === '') {
            formik.setFieldValue(`experiment.variants.${index}.distribution_share`, 0);
            return;
        }

        // Allow decimal point without a leading zero
        if (inputValue === '.') {
            e.target.value = '0.';
            return;
        }

        const value = parseFloat(inputValue);
        if (isNaN(value)) return;
        if (value > 100) {
            formik.setFieldValue(`experiment.variants.${index}.distribution_share`, 1);
            return;
        }
        if (value < 0) {
            formik.setFieldValue(`experiment.variants.${index}.distribution_share`, 0);
            return;
        }

        formik.setFieldValue(`experiment.variants.${index}.distribution_share`, value / 100);
    }, [formik.setFieldValue]);

    const getTotalDistributionExcluding = useCallback((variants: Variant[], excludeIndex: number) => {
        return variants.reduce(
            (sum, v, i) => sum + (i === excludeIndex ? 0 : (v.distribution_share || 0)),
            0
        );
    }, []);

    const handleDistributionKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === '=' || e.key === '+') {
            e.preventDefault();
            const remainingShare = 1 - getTotalDistributionExcluding(formik.values.experiment.variants, index);
            formik.setFieldValue(`experiment.variants.${index}.distribution_share`, remainingShare);
        }
    }, [formik.setFieldValue, getTotalDistributionExcluding]);

    const isValidVariantName = useCallback((value: string) => {
        return value === '' || VARIANT_NAME_REGEX.test(value);
    }, []);

    const handleVariantNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value.slice(0, MAX_VARIANT_NAME_LENGTH);
        if (isValidVariantName(value)) {
            setLastRejectedName('');
            formik.setFieldValue(`experiment.variants.${index}.variant_name`, value);
        } else {
            setLastRejectedName(value);
        }
    }, [formik.setFieldValue, isValidVariantName]);

    const confirmRemoveVariant = (remove: (index: number) => void, index: number) => {
        handleRemoveVariant(remove, index);
    };

    const { totalDistribution, isDistributionInvalid, variantErrors, canAddMoreVariants } = useMemo(() => {
        const variants = formik.values.experiment.variants || [];
        const total = variants.reduce(
            (sum, variant) => sum + (variant.distribution_share || 0),
            0
        );

        const errors: string[] = [];

        // Basic validations
        if (formik.values.experiment.enabled_experiment) {
            if (variants.length < MIN_VARIANTS) {
                errors.push(`Se requieren al menos ${MIN_VARIANTS} variantes para un experimento.`);
            }
            if (formik.values.experiment.name.length < MIN_NAME_LENGTH) {
                errors.push(`El nombre del experimento debe tener al menos ${MIN_NAME_LENGTH} caracteres.`);
            }
            if (formik.values.experiment.description.length < MIN_DESCRIPTION_LENGTH) {
                errors.push(`La descripción del experimento debe tener al menos ${MIN_DESCRIPTION_LENGTH} caracteres.`);
            }
        }

        if (variants.length >= MAX_VARIANTS) {
            errors.push(`No se pueden agregar más de ${MAX_VARIANTS} variantes.`);
        }

        const names = variants.map(v => v.variant_name) || [];
        const duplicates = names.filter((name, index) => names.indexOf(name) !== index);
        if (duplicates.length > 0) {
            errors.push('Los nombres de las variantes deben ser únicos.');
        }

        variants.forEach((variant, index) => {
            if (!variant.isControlGroup) {
                if (formik.values.experiment.experiment_on === ExperimentOn.TEMPLATE && !variant.message_template_id) {
                    errors.push(`La variante ${variant.variant_name} requiere una plantilla.`);
                }
                if (formik.values.experiment.experiment_on === ExperimentOn.SCHEDULED_AT && !variant.scheduled_at) {
                    errors.push(`La variante ${variant.variant_name} requiere una fecha de programación.`);
                }
                if (formik.values.experiment.experiment_on === ExperimentOn.TIME && !variant.time) {
                    errors.push(`La variante ${variant.variant_name} requiere un tiempo.`);
                }
            }
        });

        // Validate individual distributions
        variants.forEach((variant) => {
            if ((variant.distribution_share || 0) > 1) {
                errors.push(`La distribución de la variante ${variant.variant_name} no puede exceder el 100%.`);
            }
        });

        // Validate variant names
        variants.forEach((variant) => {
            if (variant.variant_name && !VARIANT_NAME_REGEX.test(variant.variant_name) && !variant.isControlGroup) {
                errors.push(`El nombre de la variante ${variant.variant_name} solo puede contener letras, números, espacios y guiones.`);
            }
        });

        // Validate minimum distribution
        variants.forEach((variant) => {
            if ((variant.distribution_share || 0) < MIN_DISTRIBUTION && variant.distribution_share !== 0) {
                errors.push(`La distribución de la variante ${variant.variant_name} debe ser al menos ${(MIN_DISTRIBUTION * 100)}%.`);
            }
        });

        // Validate scheduled dates
        variants.forEach((variant) => {
            if (!variant.isControlGroup && formik.values.experiment.experiment_on === ExperimentOn.SCHEDULED_AT) {
                if (variant.scheduled_at) {
                    const scheduledDate = new Date(variant.scheduled_at);
                    //errors.push(`La fecha programada para la variante ${variant.variant_name} debe ser en el futuro.`);
                }
            }
        });

        return {
            totalDistribution: total,
            isDistributionInvalid: Math.abs(total - 1) > 0.01,
            variantErrors: errors,
            canAddMoreVariants: variants.length < MAX_VARIANTS
        };
    }, [formik.values.experiment.variants, formik.values.experiment.experiment_on, formik.values.experiment.enabled_experiment,
    formik.values.experiment.name, formik.values.experiment.description]);

    const isFieldInvalid = useCallback((touched: boolean | undefined, error: string | undefined) => {
        return Boolean(touched && error);
    }, []);

    const _clearDate = () => {
        if (dateRef.current) {
            dateRef.current.flatpickr.clear();
            formik.setFieldValue("scheduled_at", null)
            formik.setFieldValue("timezone", null)
        }
    }

    return <Card>
        <CardHeader className="bg-primary text-white border-0 font-bold d-flex align-items-center gap-2">
            {!campaign?.experiment?.status && <>
                <FormGroup switch>
                    <Input type="switch"
                        id="enable-experiment-switch"
                        checked={formik.values.experiment.enabled_experiment}
                        onClick={() => {
                            formik.setFieldValue("experiment.enabled_experiment", !formik.values.experiment.enabled_experiment)
                            if (!formik.values.experiment.enabled_experiment) {
                                formik.setFieldValue("experiment.variants", [])
                            }
                        }}
                        aria-label="Habilitar experimentos A/B"
                        title="Habilitar experimentos A / B"
                    />
                    <UncontrolledTooltip target="enable-experiment-switch">
                        Habilitar o deshabilitar experimentos A/B
                    </UncontrolledTooltip>
                </FormGroup>
                <span className="las la-flask fs-4"></span>
                <span>
                    {!formik.values.experiment.enabled_experiment && <>Habilitar </>}
                    Experimentos A / B
                </span>
            </>}
            {campaign?.experiment?.status && <>
                <span className="las la-flask fs-4"></span>
                <span>Experimento actual A / B: <ExperimentStatus status={campaign.experiment.status} /></span>
            </>}
        </CardHeader>
        {
            (campaign?.experiment?.status || formik.values.experiment.enabled_experiment) && <CardBody className="bg-white text-dark">
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Tipo de experimento</Label>
                            <Input
                                type="select"
                                name="experiment.experiment_on"
                                value={formik.values.experiment.experiment_on}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                invalid={!!(formik.errors?.experiment?.experiment_on && formik.touched?.experiment?.experiment_on)}
                            >
                                <option value="">---</option>
                                {Object.keys(ExperimentOn).map((each) =>
                                    <option key={each} value={each}>
                                        {ExperimentOnTitle[each as ExperimentOn]}</option>)}
                            </Input>
                            {formik.errors?.experiment?.experiment_on && formik.touched?.experiment?.experiment_on ? (
                                <FormFeedback type="invalid" className='d-block'>{formik.errors?.experiment?.experiment_on}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Nombre del experimento</Label>
                            <Input
                                type="text"
                                name="experiment.name"
                                value={formik.values.experiment.name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                invalid={!!(formik.errors?.experiment?.name && formik.touched?.experiment?.name)}
                            />
                            {formik.errors?.experiment?.name && formik.touched?.experiment?.name ? (
                                <FormFeedback type="invalid" className='d-block'>{formik.errors?.experiment?.name}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Descripción del experimento</Label>
                            <Input
                                type="text"
                                name="experiment.description"
                                value={formik.values.experiment.description}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                invalid={!!(formik.errors?.experiment?.description && formik.touched?.experiment?.description)}
                            />
                            {formik.errors?.experiment?.description && formik.touched?.experiment?.description ? (
                                <FormFeedback type="invalid" className='d-block'>{formik.errors?.experiment?.description}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="experiment.variants"
                                render={({ remove }) => (
                                    <div className="mt-2">
                                        <div className="mb-3">
                                            <Button
                                                id="add-variant-btn"
                                                disabled={!!!formik.values.experiment.experiment_on || !canAddMoreVariants}
                                                color="info"
                                                className="me-2"
                                                size="sm"
                                                onClick={addVariant}
                                                aria-label="Agregar nueva variante"
                                            >
                                                <i className="mdi mdi-plus"></i>{' '}Agregar variante
                                            </Button>
                                            <UncontrolledTooltip target="add-variant-btn">
                                                Agregar una nueva variante al experimento
                                            </UncontrolledTooltip>

                                            {!formik.values.experiment.variants?.some(v => v.isControlGroup) && (
                                                <Button
                                                    id="add-control-btn"
                                                    disabled={!!!formik.values.experiment.experiment_on}
                                                    color="secondary"
                                                    size="sm"
                                                    onClick={addControlGroup}
                                                    aria-label="Agregar grupo de control"
                                                    title="El grupo de control no recibirá ningún mensaje y se usará para comparar resultados."
                                                >
                                                    <i className="mdi mdi-plus"></i>{' '}Agregar grupo de control
                                                </Button>
                                            )}
                                        </div>

                                        {(!formik.values.experiment.variants || formik.values.experiment.variants.length === 0) && (
                                            <Alert color="info" className="mb-3">
                                                Primero seleccione un tipo de experimento y luego agregue variantes o un grupo de control para comenzar.
                                            </Alert>
                                        )}

                                        {lastRejectedName && (
                                            <Alert color="warning" className="mb-3">
                                                El nombre "{lastRejectedName}" contiene caracteres no permitidos. Solo se permiten letras, números, espacios y guiones.
                                            </Alert>
                                        )}

                                        {variantErrors.length > 0 && (
                                            <Alert color="danger" className="mb-3">
                                                <ul className="mb-0">
                                                    {variantErrors.map((error, index) => (
                                                        <li key={index}>{error}</li>
                                                    ))}
                                                </ul>
                                            </Alert>
                                        )}

                                        {isDistributionInvalid && formik.values.experiment.variants?.length > 0 && (
                                            <Alert color="warning" className="mb-3">
                                                La distribución total debe sumar 100% (actualmente: {(totalDistribution * 100).toFixed(1)}%)
                                                {!isAutoDistributing && (
                                                    <Button
                                                        color="link"
                                                        className="p-0 ms-2"
                                                        onClick={() => {
                                                            setIsAutoDistributing(true);
                                                            formik.setFieldValue(
                                                                "experiment.variants",
                                                                redistributeShares(formik.values.experiment.variants)
                                                            );
                                                        }}
                                                    >
                                                        Distribuir en porcentajes iguales
                                                    </Button>
                                                )}
                                            </Alert>
                                        )}

                                        {isLoadingTemplates && formik.values.experiment.experiment_on === ExperimentOn.TEMPLATE && (
                                            <div className="text-center mb-3" role="status" aria-live="polite">
                                                <Spinner size="sm" aria-hidden="true" />
                                                <span className="ms-2">Cargando plantillas...</span>
                                            </div>
                                        )}

                                        {templateError && (
                                            <Alert color="danger" className="mb-3">
                                                {templateError}
                                            </Alert>
                                        )}

                                        <Row>
                                            <Col sm={12}>
                                                {formik.errors?.experiment?.variants && typeof formik.errors.experiment.variants === 'string' && (
                                                    <FormFeedback type="invalid" className='d-block alert alert-danger'>
                                                        {formik.errors.experiment.variants}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                        </Row>
                                        {formik.values.experiment.variants?.map((variant: Variant, index: number) => (
                                            <div key={index}>
                                                <Row className={`rounded p-2 mb-2 ${variant.isControlGroup ? 'bg-light-subtle border' : ''}`}>
                                                    <Col style={{ fontSize: '10px' }} sm={variant.isControlGroup || campaign?.experiment?.status ? 4 : 3}>
                                                        <FormGroup>
                                                            <Label style={{ fontSize: '10px' }} className="d-flex justify-content-between align-items-center">
                                                                <span>
                                                                    {variant.isControlGroup ? (
                                                                        <span className="d-flex align-items-center gap-2">
                                                                            Grupo de control
                                                                            <UncontrolledTooltip target={`control-group-${index}`}>
                                                                                El grupo de control no recibirá ningún mensaje y se usará para comparar resultados
                                                                            </UncontrolledTooltip>
                                                                            <i id={`control-group-${index}`} className="mdi mdi-information-outline text-muted" />
                                                                        </span>
                                                                    ) : (
                                                                        <>Nombre de variante</>
                                                                    )}
                                                                </span>
                                                                {!variant.isControlGroup && (
                                                                    <small className="text-muted">
                                                                        ({MAX_VARIANT_NAME_LENGTH - (variant.variant_name?.length || 0)} caracteres)
                                                                    </small>
                                                                )}
                                                                {variant.isControlGroup && !campaign?.experiment?.status && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link btn-sm text-muted p-0"
                                                                        onClick={() => confirmRemoveVariant(remove, index)}
                                                                        aria-label="Eliminar grupo de control"
                                                                        id={`delete-control-${index}`}
                                                                        title="Eliminar grupo de control"
                                                                    >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </button>
                                                                )}
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                bsSize="sm"
                                                                name={`experiment.variants.${index}.variant_name`}
                                                                value={variant.variant_name}
                                                                onChange={(e) => handleVariantNameChange(e, index)}
                                                                readOnly={variant.isControlGroup}
                                                                maxLength={MAX_VARIANT_NAME_LENGTH}
                                                                aria-label={variant.isControlGroup ? 'Nombre del grupo de control' : `Nombre de la variante ${index}`}
                                                                className={(variant.isControlGroup ? 'bg-light-subtle' : '')}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {!variant.isControlGroup && (
                                                        <>
                                                            {!campaign?.experiment?.status && (
                                                                <Col sm={1} className="d-flex align-items-end">
                                                                    <button
                                                                        type="button"
                                                                        className='btn btn-sm mb-3 btn-danger'
                                                                        onClick={() => confirmRemoveVariant(remove, index)}
                                                                        aria-label={`Eliminar variante ${variant.variant_name}`}
                                                                        id={`delete-variant-${index}`}
                                                                    >
                                                                        <i className='mdi mdi-delete'></i>
                                                                    </button>
                                                                    <UncontrolledTooltip target={`delete-variant-${index}`}>
                                                                        Eliminar la variante {variant.variant_name}. Los porcentajes se redistribuirán automáticamente.
                                                                    </UncontrolledTooltip>
                                                                </Col>
                                                            )}
                                                            <Col sm={4}>
                                                                <FormGroup>
                                                                    <Label style={{ fontSize: '10px' }}>{ExperimentOnLabel[formik.values.experiment.experiment_on as ExperimentOn]}</Label>
                                                                    {formik.values.experiment.experiment_on === ExperimentOn.TEMPLATE && (
                                                                        <TemplateDropdown
                                                                            required
                                                                            name={`experiment.variants.${index}.message_template_id`}
                                                                            className="form-control"
                                                                            value={variant.message_template_id}
                                                                            onBlur={formik.handleBlur}
                                                                            onChange={formik.handleChange}
                                                                            invalid={isFieldInvalid(
                                                                                formik.touched?.experiment?.variants?.[index]?.message_template_id,
                                                                                formik.errors?.experiment?.variants?.[index] &&
                                                                                    isVariantError(formik.errors?.experiment?.variants?.[index])
                                                                                    ? (formik.errors?.experiment?.variants[index] as FormikErrors<Variant>).message_template_id
                                                                                    : undefined
                                                                            )}
                                                                            selectLabel="-- Seleccione la plantilla de mensaje que enviará esta variante --"
                                                                            showPreviewButton
                                                                        />
                                                                    )}
                                                                    {formik.values.experiment.experiment_on === ExperimentOn.SCHEDULED_AT && (

                                                                        <div className="input-group input-group-sm">
                                                                            <Button color='danger' onClick={() => _clearDate()}>
                                                                                <i className="mdi mdi-close"></i>
                                                                            </Button>
                                                                            <Flatpickr
                                                                                ref={dateRef}
                                                                                className="form-control"
                                                                                value={variant.scheduled_at}
                                                                                options={{
                                                                                    locale: Spanish,
                                                                                    enableTime: true,
                                                                                }}
                                                                                onChange={(selectedDates: any, dateStr: any, instance: any) => {
                                                                                    let value;

                                                                                    if (selectedDates) {
                                                                                        value = moment(selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss')
                                                                                    } else {
                                                                                        value = null
                                                                                    }

                                                                                    formik.setFieldValue(`experiment.variants.${index}.scheduled_at`, value)
                                                                                }}
                                                                            />
                                                                            {(formik.errors.experiment?.variants && formik.errors.experiment?.variants[index]) ? (
                                                                                <FormFeedback type="invalid" className='d-block'>{(formik.errors?.experiment?.variants[index] as FormikErrors<Variant>)?.scheduled_at}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                    {formik.values.experiment.experiment_on === ExperimentOn.TIME && (
                                                                        <TimeInput
                                                                            name={`experiment.variants.${index}.time`}
                                                                            value={variant.time}
                                                                            formik={formik}
                                                                        />
                                                                    )}
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                    )}
                                                    <Col sm={2} style={{ fontSize: '10px' }}>
                                                        <FormGroup>
                                                            <Label style={{ fontSize: '10px' }} id={`distribution-label-${index}`}>Distribución (%)</Label>
                                                            <UncontrolledTooltip target={`distribution-label-${index}`}>
                                                                Porcentaje de usuarios que recibirán esta variante. Use + o = para asignar el porcentaje restante.
                                                            </UncontrolledTooltip>
                                                            <div className="input-group input-group-sm" role="group" aria-labelledby={`distribution-label-${index}`}>
                                                                <Input
                                                                    type="text"
                                                                    inputMode="decimal"
                                                                    name={`experiment.variants.${index}.distribution_share`}
                                                                    value={((variant.distribution_share || 0) * 100).toFixed(1)}
                                                                    onChange={(e) => handleDistributionChange(e, index)}
                                                                    onKeyDown={(e) => handleDistributionKeyDown(e, index)}
                                                                    onBlur={(e) => {
                                                                        formik.handleBlur(e);
                                                                        const value = parseFloat(e.target.value);
                                                                        if (!isNaN(value)) {
                                                                            const rounded = Math.min(100, Math.max(0, Math.round(value * 10) / 10));
                                                                            formik.setFieldValue(
                                                                                `experiment.variants.${index}.distribution_share`,
                                                                                rounded / 100
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                `experiment.variants.${index}.distribution_share`,
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                    placeholder="0.0"
                                                                    aria-label={`Distribución para variante ${variant.variant_name}`}
                                                                    className={`text-end ${variant.isControlGroup ? 'bg-light-subtle' : ''}`}
                                                                />
                                                                <span className="input-group-text" aria-hidden="true">%</span>
                                                            </div>
                                                            {formik.errors?.experiment?.variants?.[index] &&
                                                                typeof formik.errors.experiment.variants[index] !== 'string' &&
                                                                (formik.errors.experiment.variants[index] as FormikErrors<Variant>)?.distribution_share &&
                                                                formik.touched?.experiment?.variants?.[index]?.distribution_share ? (
                                                                <FormFeedback type="invalid" className='d-block'>
                                                                    {(formik.errors.experiment.variants[index] as FormikErrors<Variant>).distribution_share}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}

                                        {formik.values.experiment.variants?.length > 0 && (
                                            <div className="mt-3 text-end">
                                                <small className="text-muted">
                                                    Distribución restante: {(100 - (totalDistribution * 100)).toFixed(1)}%
                                                </small>
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </FormikProvider>
                    </Col>
                </Row>
            </CardBody>
        }
    </Card>
}

export default ExperimentForm